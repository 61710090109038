/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { Box, Flex, Button, Link } from 'rebass';

import { H2, H3, H4, P } from '../components/Text';
import { Cross } from '../components/Cross';
import { Ul, Li } from '../components/List';
import { ProgramLayout } from '../layouts/ProgramLayout';
import { Container } from '../components/Container';

const CFPHeaderGrid: React.FC = ({ children }) => (
  <div
    sx={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      rowGap: [50, 55, 60],
      columnGap: 20,
      mb: 72,
    }}
  >
    {children}
  </div>
);

interface CFPSectionProps {
  title: string;
  children?: React.ReactNode;
}
const CFPSection: React.FC<CFPSectionProps> = ({
  title,
  children,
  ...restProps
}) => {
  const [isActive, setActive] = useState(false);

  return (
    <div {...restProps}>
      <Flex
        alignItems="baseline"
        justifyContent="space-between"
        role="button"
        sx={{
          cursor: 'pointer',
          py: [50, 60, 72],
          borderTop: 'solid 1px',
          borderColor: 'text',
        }}
        onClick={() => setActive(s => !s)}
      >
        <H2 sx={{ width: '90%' }}>{title}</H2>
        <Cross
          sx={{ transform: isActive ? 'rotateZ(45deg)' : 'rotateZ(0deg)' }}
        />
      </Flex>
      {isActive && (
        <Box
          sx={{
            lineHeight: '22px',
            mt: 3,
            fontSize: [0, 1],
            whiteSpace: 'pre-line',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

interface CFPThemeProps {
  title: string;
  body: string;
}
const CFPTheme: React.FC<CFPThemeProps> = ({ title, body }) => (
  <Flex sx={{ flexDirection: ['column', 'column', 'row'], mb: [50, 60, 72] }}>
    <H3
      sx={{
        mr: 10,
        width: ['100%', '100%', '50%'],
        overflowWrap: 'break-word',
        textAlign: 'center',
        fontSize: ['18px', '18px', 'auto'],
      }}
    >
      {title}
    </H3>
    <P sx={{ ml: 10, width: ['100%', '100%', '50%'] }}>{body}</P>
  </Flex>
);

const CFPPage: React.FC = () => {
  return (
    <ProgramLayout programDays={[]}>
      {({ tzOffset }) => (
        <Container>
          <CFPHeaderGrid>
            <Box sx={{ gridColumn: ['1/3', '1/3', '1 / 2'] }}>
              <H2>Thank you!</H2>
              <H4>The call for proposals is closed.</H4>
              <P>
                Please check back in mid-November for announcements about our
                official program. <br /> Applicants can expect to hear back if
                they were selected on or around{' '}
                <strong sx={{ fontWeight: 'bold' }}>30 October 2020</strong>.
              </P>
              <P>
                If you wish to submit to the 2020 Interaction Awards, the Call
                for Proposals is open until{' '}
                <strong sx={{ fontWeight: 'bold' }}>31 October 2020</strong>.{' '}
                <Link href="https://awards.ixda.org/" target="_blank">
                  Learn More ↗
                </Link>
              </P>
            </Box>
            <Box></Box>
            <Box sx={{ gridColumn: ['1/3', '1/3', '2 / 3'] }}>
              <P>
                We are proud to share that our submission pool was one of our
                most diverse yet, reflecting the global reach of our growing
                IxDA community.
              </P>
              <P>
                Total:{' '}
                <strong sx={{ fontWeight: 'bold' }}>374 submissions</strong> (65
                workshops, 307 talks)
              </P>
              <Ul>
                <Li>
                  <strong sx={{ fontWeight: 'bold' }}>52%</strong> of submitters
                  identify as women, and{' '}
                  <strong sx={{ fontWeight: 'bold' }}>7%</strong> as gender
                  non-conforming, non-binary, or two-spirit
                </Li>
                <Li>
                  <strong sx={{ fontWeight: 'bold' }}>59 countries</strong>, and
                  all continents except Antarctica
                </Li>
                <Li>
                  <strong sx={{ fontWeight: 'bold' }}>74%</strong> of submitters
                  have never spoken at an IxDA event before
                </Li>
                <Li>
                  <strong sx={{ fontWeight: 'bold' }}>16</strong> courageous
                  submitters would be first-time speakers at a conference
                </Li>
                <Li>
                  Our most popular theme with{' '}
                  <strong sx={{ fontWeight: 'bold' }}>37%</strong> of
                  submissions is "New World to Design"
                </Li>
              </Ul>
            </Box>

            <Box
              sx={{
                borderColor: 'text',
                borderBottom: 'solid 1px',
                gridColumn: '1/3',
              }}
            />
          </CFPHeaderGrid>
          <CFPHeaderGrid>
            <P sx={{ gridColumn: ['1/3', '1/3', '2 / 3'] }}>
              Politics. Pandemics. Economic disruption. Social injustices.
              Climate crises. Privacy wars. Deep fakes.. Our theme, Design In
              Perilous Times, asks: “How do we destroy the poisonous paradigms
              seeping into all areas of our lives?”
            </P>
            <H2 sx={{ gridColumn: '1 / 3', textAlign: 'center' }}>
              We seek talks, panels, sessions, and workshops by individuals from
              all walks of life. From within and beyond the screen, we welcome a
              wide spectrum of interaction design insights — architects,
              managers, artists, service designers, communications experts, and,
              of course, interface and user experience folx.
            </H2>
            <P
              sx={{
                gridColumn: '1 / 3',
                width: '80%',
                textAlign: 'center',
                justifySelf: 'center',
              }}
            >
              The Interaction 21 team is available to support all speakers with
              coaching and reviewing of presentations. You are welcome to
              present in the language of your choice, and we will provide
              translation as required. Talks will be live captioned.
            </P>
            <div
              sx={{
                gridColumn: ['1/3', '1/3', '2 / 3'],
                width: ['auto', 'auto', '66%'],
              }}
            >
              <H3>Anonymity</H3>
              <P>
                IxD21’s online format has the unique affordance of allowing
                presenters to share their insights anonymously. We welcome
                proposals that would otherwise not be presented on stage - be
                they delicate, controversial or simply better understood when
                presented anonymously.
              </P>
            </div>
          </CFPHeaderGrid>

          <CFPSection title="themes">
            <Box sx={{ mb: [60, 60, 100] }}>
              <P sx={{ width: ['100%', '100%', '50%'] }}>
                This year’s key themes are yours to mix and match. And you don’t
                have to be bound by them — freed from the traditional conference
                format, we are open to proposals exploring perils outside our
                suggested topics.
              </P>
            </Box>
            <CFPTheme
              title="#Design_Economics"
              body="
            The free market changed us. As profit interests and rapacious
            billionaires colonize our data and infiltrate our digital and
            everyday spaces — privacy, trust, and truthfulness are in peril. We
            have become increasingly divided, disoriented and exploitable. How
            do we wrestle with the perils of profit in design?"
            />
            <CFPTheme
              title="#New_World_to_Design"
              body="
        Triggered by Covid-19, and with climate change devouring ecosystems,
        societal inequalities are highlighted and we are in a state of
        emergency. This world of ours on fire is urging us to come together
        on local and global scales. Facing the situation with stoicism, we
        can but seize the opportunity afforded by these crises."
            />
            <CFPTheme
              title="#Dark_Design"
              body="
          Aspects of our humanity are central to design. Sexuality, addiction, 
          human flaws and desires are considered and oftentimes exploited. 
          There isn’t always a supply and demand — we engineer damaging sticky 
          products for a premium. Enough laissez-faire, let’s get down and dirty, and expose ourselves."
            />
            <CFPTheme
              title="#Designing_Design"
              body="Good designers help others thrive by guiding them through perils.  
          A strong community is united and organized — it’s time we talk about 
          unions. We are calling design leaders, and those in the trenches to 
          arms. Let’s gather around the fire and share our tales of success, 
          sure, but mostly of failures, and lessons learned."
            />
            <CFPTheme
              title="#Future"
              body="Sci-fi and speculative fiction terrify and inspire us to 
          dream up revolutionary possibilities with courage. To survive 
          peril, we must wield design as creative defiance.  We need to 
          deeply unpack our assumptions and biases about prospects. 
          Deconstruction is our scaffolding."
            />
          </CFPSection>
          <CFPSection title="Formats">
            <Box sx={{ mb: 60 }}>
              <P>
                We are seeking talks of different lengths to accommodate a
                variety of approaches and insights:
              </P>
            </Box>
            <H3>Talks</H3>
            <Box
              sx={{
                my: [60, 60, 100],
                ml: ['-5%', 0, '10%'],
                width: ['110%', '100%', '80%'],
                display: 'grid',
                gridAutoFlow: 'column',
                gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
                gridTemplateRows: ['repeat(4, auto)', 'auto auto'],
                rowGap: [48, 30, 12],
                '*': {
                  textAlign: 'center',
                },
              }}
            >
              <H3>00:15:00</H3>
              <P>
                15 minute talks which expand on one straightforward use-case or
                concept;
              </P>
              <H3>00:25:00</H3>
              <P>
                25 minute talks which trace a very rich, full-fledged and well
                articulated concept or historical walkthrough.
              </P>
            </Box>
            <H3>Workshops</H3>
            <Box sx={{ my: 60 }}>
              <P>
                If you are driven by smaller groups and interactive exercises
                you are welcome to host a workshop.
              </P>
              <Flex
                sx={{
                  flexDirection: ['column', 'column', 'row'],
                }}
              >
                <P sx={{ width: ['100%', '100%', '50%'] }}>
                  We will have a mix of{' '}
                  <strong sx={{ fontWeight: 700 }}>short (2h)</strong> and{' '}
                  <strong sx={{ fontWeight: 700 }}>long (4h)</strong> workshops.
                </P>
                <P sx={{ width: ['100%', '100%', '50%'] }}>
                  From very practical activities with tangible outcomes to more
                  visionary activities, always ask yourself what the
                  participants would take away from your session, and of course
                  if it’s relevant to the larger conference theme.
                </P>
              </Flex>
            </Box>
            <H3>Roundtable</H3>
            <Box sx={{ my: 60 }}>
              <P>
                We are opening up the stage to roundtables or anti-conferences
                this year, aiming to engage the audience with one or many
                experts - that’s you - who introduces a topic for around 10
                minutes, and then hosts a discussion of either 20 or 50 minutes.
                Apply with your friends!
              </P>
            </Box>
            <H3>1 on 1 Mentorship</H3>
            <Box sx={{ my: 60 }}>
              <P>
                We are opening participation to experts who have time to give
                for juniors’ mentorship. To participate, check the appropriate
                section in the submission form.
              </P>
            </Box>
          </CFPSection>

          <CFPSection title="How to submit a proposal">
            <Flex sx={{ flexDirection: ['column', 'column', 'row'], mb: 20 }}>
              <Box sx={{ flex: 1, mr: 20 }}>
                <H4>What happens after you submit your proposal</H4>
              </Box>
              <Box sx={{ flex: 2 }}>
                <P>
                  Your proposals are peer reviewed by members from the IxDA
                  community - our selection committee. We review on the basis of
                  quality, speaker experience, how well it aligns with the
                  conference themes and other presentations, keeping in mind the
                  overall aim: diversity of point of views.
                </P>
                <P>Here are key dates for you to remember:</P>
                <Ul>
                  <Li>
                    Deadline for submitting the proposals:{' '}
                    <strong sx={{ fontWeight: 700 }}>25 September</strong>
                  </Li>
                  <Li>
                    Selection confirmation:{' '}
                    <strong sx={{ fontWeight: 700 }}>30 October</strong>
                  </Li>
                  <Li>
                    Full Program and Workshops announced:{' '}
                    <strong sx={{ fontWeight: 700 }}>16 November</strong>
                  </Li>
                </Ul>
              </Box>
            </Flex>
            <Flex sx={{ flexDirection: ['column', 'column', 'row'], mb: 20 }}>
              <Box sx={{ flex: 1, mr: 20 }}>
                <H4>What’s in it for you?</H4>
              </Box>
              <Box sx={{ flex: 2 }}>
                <P>
                  You will be in the position to shape the discourse of the
                  Interaction Design community, and you'll have bragging rights
                  for being among the first to be featured in this innovative
                  format.
                </P>
                <P>
                  For each talk, you receive 1 free ticket for the conference
                  and an honorarium (TBD). For each workshop, you get 50% of the
                  proceeds from your workshop ticket sales and 1 free ticket for
                  the conference.
                </P>
                <P>
                  You will also receive the technical assistance, instructions,
                  support and recording material needed to ensure the highest
                  possible production value for your segment.
                </P>
              </Box>
            </Flex>
            <Flex sx={{ flexDirection: ['column', 'column', 'row'], mb: 20 }}>
              <Box sx={{ flex: 1, mr: 20 }}>
                <H4>Anonymity details</H4>
              </Box>
              <Box sx={{ flex: 2 }}>
                <P>
                  Inspired by guidelines effective in Canadian journalism and
                  law, IxD21 will grant anonymity for the following reasons:
                </P>
                <Ul>
                  <Li>
                    The information was only possible to obtain under the guise
                    of confidentiality.
                  </Li>
                  <Li>
                    The source’s willingness to give a talk depends on
                    anonymity.
                  </Li>
                  <Li>The information must be of public interest.</Li>
                  <Li>
                    The disclosure of identity may incur harm to the presenting
                    party.
                  </Li>
                </Ul>
                <P>
                  To submit anonymously, write us at{' '}
                  <a
                    sx={{ variant: 'variants.link' }}
                    href="mailto:interaction21@protonmail.com"
                  >
                    interaction21@protonmail.com
                  </a>{' '}
                  with the details of your talk, taking into consideration the
                  details mentioned above. We'll get back to you at the latest
                  on October 30th.
                </P>
                <H4>Verification</H4>
                <P>
                  Our design community’s trust is the wind in IxDA’s sails.
                  IxD21 will conduct a thorough vetting process and will
                  fact-check anonymous talks to ensure our sources' credibility.
                </P>
              </Box>
            </Flex>
            <Flex sx={{ flexDirection: ['column', 'column', 'row'], mb: 20 }}>
              <Box sx={{ flex: 1, mr: 20 }}>
                <H4>Ready?</H4>
              </Box>
              <Box sx={{ flex: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                  as="a"
                  href="https://ixda.submittable.com/submit/168579/interaction-21"
                  target="_blank"
                  sx={{
                    alignItems: 'center',
                    lineHeight: '32px',
                    textAlign: 'left',
                    cursor: 'pointer',
                  }}
                >
                  Submit a proposal ↗
                </Button>
              </Box>
            </Flex>
          </CFPSection>
        </Container>
      )}
    </ProgramLayout>
  );
};

export default CFPPage;
