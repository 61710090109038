/** @jsx jsx */
import { jsx } from 'theme-ui';

const Cross = ({ ...restProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    sx={{ fill: 'text', transition: 'transform', transitionDuration: '0.125s' }}
    {...restProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 0H7.5V7.5L0 7.5V9.5H7.5V17H9.5V9.5H17V7.5L9.5 7.5V0Z"
    />
  </svg>
);

export { Cross };
